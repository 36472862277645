<template>
  <div
    class="border-t flex cursor-pointer border-neutral-50 px-3 transition-all duration-200 hover:bg-neutral-0 md:px-6"
  >
    <div
      class="flex-grow w-5/12 flex-col justify-center p-2 py-4 pr-4 xl:w-4/12"
      @click="click"
    >
      <slot name="colOne"></slot>
    </div>
    <div
      class="hidden w-2/12 flex-col justify-center p-2 py-4 pr-4 xl:flex"
      @click="click"
    >
      <slot name="colTwo"></slot>
    </div>
    <div
      class="hidden w-3/12 flex-col justify-center p-2 py-4 pr-4 xl:flex"
      @click="click"
    >
      <slot name="colThree"></slot>
    </div>
    <div class="flex w-3/12 flex-col justify-center py-4">
      <slot name="colFour"></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>
