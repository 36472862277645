<template>
  <div>
    <ModalSideContent class="grid gap-4">
      <ErrorAlert v-if="formError" :text="formError" />

      <SelectField
        v-model="storeUuid"
        :label="$t('components.operations.preOpening.form.storeLabel')"
        @input="updateStoreUuid"
      >
        <option value="-1" selected disabled>
          {{ $t("components.operations.preOpening.form.selectStore") }}
        </option>
        <option v-for="store in stores" :key="store.uuid" :value="store.uuid">
          {{ store.organisation.name }} - {{ store.name }}
        </option>
      </SelectField>
    </ModalSideContent>
    <ModalSideContent>
      <div class="mb-4 text-xs font-medium uppercase text-neutral-900">
        {{ $t("components.operations.preOpening.form.dueDates") }}
      </div>

      <div class="grid gap-6">
        <div
          v-for="stage in stages"
          :key="stage.details.uuid"
          class="grid grid-cols-2"
        >
          <div>
            <div class="mr-2 text-sm font-medium text-neutral-600">
              {{ stage.details.name }}
            </div>
            <div class="text-xs text-neutral-400">
              {{
                $t("components.operations.preOpening.turnaround", {
                  amount: turnaroundSlug(stage.details.turnaround),
                })
              }}
            </div>
          </div>

          <DateInput
            v-model="stage.value"
            :align-right="true"
            @input="updateStages"
          />
        </div>
      </div>
    </ModalSideContent>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import addSubtractDate from "add-subtract-date";
import ErrorAlert from "@/components/common/ErrorAlert";
import DateInput from "@/components/common/DateInput";
import ModalSideContent from "@/components/common/ModalSideContent";

export default {
  components: {
    ErrorAlert,
    DateInput,
    ModalSideContent,
  },

  data() {
    return {
      storeUuid: null,
      stages: [],
    };
  },

  computed: {
    ...mapGetters("preOpeningForm", ["formError"]),
    ...mapGetters("preOpeningStages", ["preOpeningStages"]),
    ...mapGetters("stores", ["stores"]),
  },

  mounted() {
    this.resetForm();

    let lastDate = new Date();
    this.stages = this.preOpeningStages.map((stage) => {
      lastDate = addSubtractDate.add(
        new Date(lastDate.getTime()),
        stage.turnaround,
        "days",
      );

      const weeks = Math.floor(stage.turnaround / 7);
      const days = stage.turnaround % 7;

      let date = [];

      if (weeks) {
        date.push(weeks + " weeks");
      }
      if (days) {
        date.push(days + " days");
      }

      const turnaroundSlug = date.join(", ");

      return {
        details: stage,
        turnaroundSlug,
        value: lastDate,
      };
    });

    this.updateStages();
  },

  methods: {
    ...mapActions("preOpeningForm", [
      "resetForm",
      "updateStoreUuid",
      "updatePreOpeningStages",
    ]),

    turnaroundSlug(totalDays) {
      const weeks = Math.floor(totalDays / 7);
      const days = totalDays % 7;
      let date = [];

      if (weeks) {
        date.push(weeks + " weeks");
      }
      if (days) {
        date.push(days + " days");
      }

      return date.join(", ");
    },

    updateStore() {
      this.updateStoreUuid(this.storeUuid);
    },

    updateStages() {
      this.updatePreOpeningStages(this.stages);
    },
  },
};
</script>
