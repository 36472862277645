<template>
  <PreOpeningTableRowFrame @click="emitClick">
    <template v-slot:colOne>
      <div class="flex items-center">
        <StoresAvatar :name="'wine'" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            {{ preOpening.store.name }}
          </div>
          <div class="truncate text-neutral-400">
            {{
              `${preOpening.store.streetAddress}, ${preOpening.store.zipCode} ${preOpening.store.city}`
            }}
          </div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div class="truncate text-sm text-neutral-600">
        {{ preOpening.store.organisation.name }}
      </div>
    </template>
    <template v-slot:colThree>
      <div class="truncate text-sm text-neutral-600">
        {{
          `${formatDate(preOpening.startDate)} - ${formatDate(
            preOpening.endDate,
          )}`
        }}
      </div>
    </template>
    <template v-slot:colFour>
      <div class="flex items-center justify-end">
        <div class="flex-grow">
          <ProgressBar :stages="preOpening.stages" :meta-visible="false" />
        </div>
        <slot name="actions"></slot>
      </div>
    </template>
  </PreOpeningTableRowFrame>
</template>

<script>
import PreOpeningTableRowFrame from "@/components/operations/preOpening/PreOpeningTableRowFrame";
import ProgressBar from "@/components/operations/preOpening/ProgressBar";
import StoresAvatar from "@/components/user-management/stores/StoresAvatar";

export default {
  components: {
    PreOpeningTableRowFrame,
    ProgressBar,
    StoresAvatar,
  },
  props: {
    preOpening: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(parsedDate);
    },
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
