<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ $t(`components.operations.preOpening.createPreOpening`) }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <CreatePreOpeningForm class="flex-grow overflow-y-scroll" />

      <ModalSideActions class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`components.operations.preOpening.createPreOpening`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="saveAndCloseModal"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalSide from "@/components/common/ModalSide";
import ModalSideActions from "@/components/common/ModalSideActions";
import CreatePreOpeningForm from "@/components/operations/preOpening/CreatePreOpeningForm";

export default {
  components: {
    ModalSide,
    ModalSideActions,
    CreatePreOpeningForm,
  },

  computed: {
    ...mapGetters("preOpeningForm", ["formError"]),
  },

  methods: {
    ...mapActions("preOpeningForm", ["createPreOpening"]),

    async saveAndCloseModal() {
      await this.createPreOpening();

      if (this.formError === null) {
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
