<template>
  <div>
    <div class="border flex h-2 rounded-full border-neutral-100 bg-neutral-50">
      <div
        v-for="stage in stages"
        :key="stage.details.uuid"
        :class="[
          'border-r group relative flex-1 border-neutral-50 first:rounded-l-full last:rounded-r-full',
          { 'bg-danger-600': stage.dueDatePassed && stage.status !== 'done' },
          { 'order-1 bg-success-600': stage.status === 'done' },
          { 'order-2': stage.status !== 'done' },
        ]"
      >
        <span
          class="invisible absolute left-1/2 bottom-full mb-2 -translate-x-1/2 transform rounded-sm bg-neutral-900 px-2 py-1 text-2xs text-white opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100"
        >
          <span
            class="absolute bottom-0 left-0 right-0 mx-auto -mb-1 h-2 w-2 rotate-45 transform bg-neutral-900"
          ></span>
          <span class="whitespace-no-wrap">
            {{ stage.details.name }}
          </span>
        </span>
      </div>
    </div>
    <div v-if="metaVisible" class="mt-2 flex items-center justify-between">
      <div class="text-xs text-neutral-400">
        {{
          $t("components.operations.preOpening.stagesRemaining", {
            number: remainingStages,
          })
        }}
      </div>
      <div v-if="currentStage" class="text-xs">
        {{ currentStage.details.name }}
        {{ $t("components.operations.preOpening.in") }}
        <span
          :class="[
            { 'text-success-600': daysRemaining >= 0 },
            { 'text-danger-600': daysRemaining < 0 },
          ]"
        >
          {{
            $t("components.operations.preOpening.timeRemaining", {
              number: remainingTimeSlug(daysRemaining),
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stages: {
      type: Array,
      required: true,
    },
    metaVisible: {
      type: Boolean,
      default: true,
    },
    doneStages: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    currentStage: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },

  computed: {
    remainingStages() {
      return this.stages.length - this.doneStages.length;
    },

    daysRemaining() {
      const oneDay = 24 * 60 * 60 * 1000;
      const currentDate = new Date().getTime();
      const currentStageDueDate = new Date(this.currentStage.dueDate).getTime();
      return Math.round((currentStageDueDate - currentDate) / oneDay);
    },
  },

  methods: {
    remainingTimeSlug(daysRemaining) {
      const weeks = Math.floor(daysRemaining / 7);
      const days = daysRemaining % 7;
      let date = [];

      if (weeks) {
        date.push(weeks + " weeks");
      }
      if (days) {
        date.push(days + " days");
      }

      return date.join(", ");
    },
  },
};
</script>
