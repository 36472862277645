<template>
  <PreOpeningTableLegendFrame>
    <template v-slot:colOne>
      {{ $t("components.operations.preOpening.storeInformation") }}
    </template>
    <template v-slot:colTwo>
      {{ $t("components.operations.preOpening.organisation") }}
    </template>
    <template v-slot:colThree>
      {{ $t("components.operations.preOpening.period") }}
    </template>
    <template v-slot:colFour>
      {{ $t("components.operations.preOpening.progress") }}
    </template>
  </PreOpeningTableLegendFrame>
</template>

<script>
import PreOpeningTableLegendFrame from "@/components/operations/preOpening/PreOpeningTableLegendFrame";

export default {
  components: {
    PreOpeningTableLegendFrame,
  },
};
</script>
