<template>
  <div class="sm:container">
    <div v-if="error">
      {{ error }}
    </div>
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("components.operations.preOpening.preOpeningsTitle") }}
        </template>
        <template v-slot:description>
          {{ $t("components.operations.preOpening.preOpeningsDescription") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            v-if="$can('write:preOpenings')"
            size="medium"
            variant="fill"
            :text="$t(`components.operations.preOpening.createPreOpening`)"
            @buttonClick="openCreateModal"
          >
            <template #iconLeft>
              <IconPlus />
            </template>
          </BaseButton>
        </template>
      </TableHeader>
      <div v-if="preOpenings.length > 0">
        <PreOpeningTableLegend />
        <div v-if="$can('write:preOpenings')">
          <PreOpeningTableRow
            v-for="preOpening in preOpeningsSortedByCreationDate"
            :key="preOpening.index"
            :pre-opening="preOpening"
            @click="openEditModal(preOpening)"
          />
        </div>
        <div v-else>
          <PreOpeningTableRow
            v-for="preOpening in preOpeningsSortedByEndDate"
            :key="preOpening.index"
            :pre-opening="preOpening"
            @click="redirectToPreOpeningBoard(preOpening.uuid)"
          >
            <template v-slot:actions>
              <BaseButton
                class="ml-8 mr-2 hidden lg:block"
                size="small"
                variant="outline"
                :text="$t(`components.operations.preOpening.viewBoard`)"
                @buttonClick="redirectToPreOpeningBoard(preOpening.uuid)"
              >
              </BaseButton>
            </template>
          </PreOpeningTableRow>
        </div>
      </div>
      <div
        v-else
        class="border-t border-neutral-50 bg-neutral-0 p-5 text-center text-sm text-neutral-400"
      >
        {{ $t("components.operations.preOpening.noPreOpenings") }}
      </div>
    </TableFrame>

    <transition name="fade">
      <CreatePreOpeningModal v-if="createModalOpen" @close="closeCreateModal" />
    </transition>
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import PreOpeningTableLegend from "@/components/operations/preOpening/PreOpeningTableLegend";
import PreOpeningTableRow from "@/components/operations/preOpening/PreOpeningTableRow";
import CreatePreOpeningModal from "@/components/operations/preOpening/CreatePreOpeningModal";

import store from "@/store";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PreOpeningOverview",

  components: {
    TableFrame,
    TableHeader,
    PreOpeningTableLegend,
    PreOpeningTableRow,
    CreatePreOpeningModal,
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([
      store.dispatch("preOpenings/fetchPreOpenings"),
      store.dispatch("preOpeningStages/fetchPreOpeningStages"),
      store.dispatch("stores/fetchStores", { withoutPreOpenings: true }),
    ]).finally(() => {
      next();
    });
  },

  data() {
    return {
      createModalOpen: false,
    };
  },

  computed: {
    ...mapGetters("preOpenings", [
      "preOpenings",
      "preOpeningsSortedByCreationDate",
      "preOpeningsSortedByEndDate",
      "error",
    ]),
  },

  methods: {
    ...mapActions("preOpenings", ["fetchPreOpenings"]),
    ...mapActions("stores", ["fetchStores"]),

    redirectToPreOpeningBoard(preOpeningUuid) {
      this.$router.push({
        name: "pre-opening-board",
        params: { preOpeningUuid },
      });
    },

    openCreateModal() {
      this.createModalOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    async closeCreateModal() {
      this.createModalOpen = false;
      document.body.classList.remove("overflow-y-hidden");

      this.fetchPreOpenings();
      this.fetchStores({ withoutPreOpenings: true });
    },

    openEditModal() {
      //@TODO: implement in BOR-969
    },
  },
};
</script>
